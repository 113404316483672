<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-card>
          <v-container fluid>
            <v-row>
              <v-col class="text-center text-h4 pa-5">
                Investments
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="text-lg-end text-center">
                <v-btn color="primary" lg="3" class="px-10" @click="$router.push('/addinvestment')">
                  Add new investment
                </v-btn>
              </v-col>
            </v-row>
            <v-row class="justify-center">
               <v-col cols="12" class="text-center">
                <span class="text-h6">Investments</span>
              </v-col>
              <v-col cols="12" lg="3">
                <v-menu
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="start_date"
                        label="Start date"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                      v-model="start_date"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" lg="3">
                <v-menu
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="end_date"
                        label="End date"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                      v-model="end_date"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" lg='2'>
                <v-btn color="primary" class="px-10" @click="getGraphData()">
                  Filter
                </v-btn>
              </v-col>
              <v-col cols="12" lg="10">
                <Chartjs :chartData="chartdata"/>
              </v-col>
            </v-row>
            <v-row class="justify-center">
              <v-col cols="12" class="text-center">
                <span class="text-h6">Investments List</span>
              </v-col>
              <v-col cols="12" lg="6">
                <v-card-title>
                  <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Search"
                    single-line
                    hide-details
                  ></v-text-field>
                </v-card-title>
              </v-col>
              <v-col cols="12">
                <v-data-table :headers="fields" :items="investments" :search="search" hide-default-footer mobile-breakpoint="0" :loading="full_loader" loading-text="Loading Data ...">
                  <template v-slot:[`item.actions`]="{ item }">
                    <v-btn small color="success" class="pa-0 ma-1" @click="$router.push(`/edit/investment/${item.id}`)">
                      <v-icon small>
                        mdi-pencil
                      </v-icon>
                    </v-btn>
                    <v-btn small color="error" class="pa-0 ma-1" @click="showDialog(item)">
                      <v-icon small>
                        mdi-delete
                      </v-icon>
                    </v-btn>
                  </template>
                  <template v-slot:[`top`]>
                    <v-dialog v-model="showdialog" max-width="500px">
                      <v-card class="pa-5">
                        <v-card-title class="text-h6">Are you sure you want to delete this item?</v-card-title>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn color="secondary" class="px-5 mr-2" @click="showdialog=false">Cancel</v-btn>
                          <v-btn color="primary" class="px-5" @click="deleteInvestment()">OK</v-btn>
                          <v-spacer></v-spacer>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </template>
                </v-data-table>
              </v-col>
              <v-col class="test-center mb-5">
                <v-pagination v-model="current_page" :length="total_pages" @next="currentPage()" @previous="currentPage()" @input="currentPage()"></v-pagination>
              </v-col>
            </v-row>
            <!-- <v-row>
              <v-col>
                <v-overlay :value="full_loader" color="white">
                  <v-progress-circular
                      indeterminate
                      size="64"
                      color="primary"
                  ></v-progress-circular>
                </v-overlay>
              </v-col>
            </v-row> -->
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {get_investments, delete_investment, get_investments_graph_data} from "@/apis/investment";
import Chartjs from '@components/base-components/investment-chart.vue'
export default {
  components: {
    Chartjs
  },
  data() {
    return {
      search:'',
      chartdata:[0,0,0,0,0,0,0,0,0,0,0,0],
      // chartdata: {
      // labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun','Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      // datasets: [{
      //       label: 'Investment',
      //       data: [0,0,0,0,0,0,0,0,0,0,0,0],
      //       backgroundColor: 'rgba(0,119,200,0.5)',
      //       borderColor: 'rgba(0,119,200,1)',
      //       borderWidth: 2
      //   },]
      // },
      fields: [
        {
          text: "Project",
          value: "project.name",
        },
        {
          text: "Ref Name",
          value: "ref_name",
        },
        {
          text: "Amount",
          value: "amount",
        },
        {
          text: "Currency",
          value: "currency",
        },
        {
          text: "Description",
          value: "description",
        },
        {
          text: "Date",
          value: "date",
        },
        {
          text: "Actions",
          value: "actions",
          align: "center",
        }
      ],
      graphData:[],
      start_date:'',
      end_date:'',
      showdialog: false,
      investment_id: "",
      investments: [],
      current_page: null,
      total_pages: 1,
      full_loader: false,
      errResp:'',
      error:''
    }
  },
  mounted() {
    this.investmentList();
  },
  methods: {
    getGraphData(){
      let data={
        start_date:this.start_date,
        end_date:this.end_date,
      }
      get_investments_graph_data(data).then(resp=>{
        if(resp.status === 'Success'){
          this.setGraphData(resp.data)
          // this.graphData=resp.data;
        }
      });
    },
    setGraphData(data){
      data.forEach(s=>{
      let month=Number(s.date.slice(5,7))
      this.chartData[`${month-1}`]+=s.amount;
      // this.chartdata.datasets[0].data[`${month-1}`]+=s.amount;
      })
    },
    showDialog(item) {
      this.investment_id = item.id;
      this.showdialog = true;
    },

    deleteInvestment() {
      delete_investment(this.investment_id).then(
          resp => {
            this.showdialog = false;
            if (resp.status == 'Success') {
              this.$toastr.s(resp.message);
              this.currentPage();
            } else {
              this.error = resp.errors;
            }
          }
      )
    },

    investmentList(data) {
      this.full_loader = true;
      get_investments(data).then(
          resp => {
            this.full_loader = false;
            if(resp.status == 'Success'){
              this.investments = resp.data.data;
              this.current_page = resp.data.current_page;
              this.total_pages = resp.data.last_page
            }else{
              this.errResp= resp.errors;
            }
            
          }
      )
    },
    currentPage() {
      var p = Number(this.current_page)
      this.investmentList({page: p})
    },
  }
}
</script>

<style>

</style>
