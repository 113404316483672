var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[_c('v-card',[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"text-center text-h4 pa-5"},[_vm._v(" Investments ")])],1),_c('v-row',[_c('v-col',{staticClass:"text-lg-end text-center",attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"px-10",attrs:{"color":"primary","lg":"3"},on:{"click":function($event){return _vm.$router.push('/addinvestment')}}},[_vm._v(" Add new investment ")])],1)],1),_c('v-row',{staticClass:"justify-center"},[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('span',{staticClass:"text-h6"},[_vm._v("Investments")])]),_c('v-col',{attrs:{"cols":"12","lg":"3"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Start date","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.start_date),callback:function ($$v) {_vm.start_date=$$v},expression:"start_date"}},'v-text-field',attrs,false),on))]}}])},[_c('v-date-picker',{model:{value:(_vm.start_date),callback:function ($$v) {_vm.start_date=$$v},expression:"start_date"}})],1)],1),_c('v-col',{attrs:{"cols":"12","lg":"3"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"End date","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.end_date),callback:function ($$v) {_vm.end_date=$$v},expression:"end_date"}},'v-text-field',attrs,false),on))]}}])},[_c('v-date-picker',{model:{value:(_vm.end_date),callback:function ($$v) {_vm.end_date=$$v},expression:"end_date"}})],1)],1),_c('v-col',{attrs:{"cols":"12","lg":"2"}},[_c('v-btn',{staticClass:"px-10",attrs:{"color":"primary"},on:{"click":function($event){return _vm.getGraphData()}}},[_vm._v(" Filter ")])],1),_c('v-col',{attrs:{"cols":"12","lg":"10"}},[_c('Chartjs',{attrs:{"chartData":_vm.chartdata}})],1)],1),_c('v-row',{staticClass:"justify-center"},[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('span',{staticClass:"text-h6"},[_vm._v("Investments List")])]),_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_c('v-card-title',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.fields,"items":_vm.investments,"search":_vm.search,"hide-default-footer":"","mobile-breakpoint":"0","loading":_vm.full_loader,"loading-text":"Loading Data ..."},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"pa-0 ma-1",attrs:{"small":"","color":"success"},on:{"click":function($event){return _vm.$router.push(("/edit/investment/" + (item.id)))}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-pencil ")])],1),_c('v-btn',{staticClass:"pa-0 ma-1",attrs:{"small":"","color":"error"},on:{"click":function($event){return _vm.showDialog(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-delete ")])],1)]}},{key:"top",fn:function(){return [_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.showdialog),callback:function ($$v) {_vm.showdialog=$$v},expression:"showdialog"}},[_c('v-card',{staticClass:"pa-5"},[_c('v-card-title',{staticClass:"text-h6"},[_vm._v("Are you sure you want to delete this item?")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"px-5 mr-2",attrs:{"color":"secondary"},on:{"click":function($event){_vm.showdialog=false}}},[_vm._v("Cancel")]),_c('v-btn',{staticClass:"px-5",attrs:{"color":"primary"},on:{"click":function($event){return _vm.deleteInvestment()}}},[_vm._v("OK")]),_c('v-spacer')],1)],1)],1)]},proxy:true}],null,true)})],1),_c('v-col',{staticClass:"test-center mb-5"},[_c('v-pagination',{attrs:{"length":_vm.total_pages},on:{"next":function($event){return _vm.currentPage()},"previous":function($event){return _vm.currentPage()},"input":function($event){return _vm.currentPage()}},model:{value:(_vm.current_page),callback:function ($$v) {_vm.current_page=$$v},expression:"current_page"}})],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }