<script>
import { Bar } from 'vue-chartjs'
export default {
  name: "Chartjs",
  extends: Bar,
  props:{
    // chartData:[Object]
    chartData:[Array],
    renderAuto:[Boolean]
  },
  data: () => ({
    
    options: {
      maintainAspectRatio: false,
        //  scales: {
        //     xAxes:[{
        //         stacked: true
        //     }],
        //     yAxes: [{
        //         stacked: true,
        //         ticks: {
        //             beginAtZero: true
        //         }
        //     }]
        // }
    }
  }),
  watch:{
    renderAuto(){
        console.log('hello change')
    },  
    // chartData:{
    //   deep:true,
    //   handler(){
    //     this.renderChart(this.chartData, this.options)
    //   }
    // }
    chartData:{
        deep:true,
        handler(){
          console.log('hello change');
          this.render();  
        }
        // console.log('hello change');
        // this.render();
    }        
  },
  mounted () {
    // this.renderChart(this.chartData, this.options)
    this.render()
  },
  methods: {
      render(){
          this.renderChart({
                labels:['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun','Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                datasets: [{
                    label: 'Investment',
                    data: this.chartData,
                    backgroundColor: 'rgba(0,119,200,0.5)',
                    borderColor: 'rgba(0,119,200,1)',
                    borderWidth: 2
                }]
          },this.options)
      }
  }
}
</script>

<style>

</style>